/*
 * @author: Razvan Rauta
 * Date: 02/10/2019
 * Time: 17:43
 */

export default function filterEmployees(employees, input) {
	let result = [];
	let $word;
	const searchByDepartments = {
		hr: 'head recruitment recruiter hr partner lead senior',
		pm: 'project manager senior delivery',
		rm: 'resource manager senior',
		qa: 'qa senior',
		project: 'project manager senior delivery',
		'project manager': 'project manager senior delivery'
	};
	let array_to_search = [];
	let inputTrim = input.replace(/^\s+|\s+$/g, '');
	if (inputTrim) {
		let fields = ['phone_numbers', 'first_name', 'last_name', 'title', 'email'];

		if (Array.isArray(employees)) {
			employees.map(employee => {
				fields.map(field => {
					let stringToSearchIn, reversedStringToSearchIn;

					switch (field) {
						case 'last_name':
						case 'first_name':
							stringToSearchIn =
								employee['last_name'] + ' ' + employee['first_name'];
							reversedStringToSearchIn =
								employee['first_name'] + ' ' + employee['last_name'];
							if (
								stringToSearchIn
									.toLowerCase()
									.indexOf(inputTrim.toLowerCase()) !== -1 ||
								reversedStringToSearchIn
									.toLowerCase()
									.indexOf(inputTrim.toLowerCase()) !== -1
							) {
								result.push(employee);
							}

							break;

						case 'phone_numbers':
							var phoneNumber = parseInt(
								input.trim().replace(/[^0-9.]/g, ''),
								10
							);
							if (phoneNumber && typeof phoneNumber === 'number') {
								employee['phone_numbers'].forEach(number => {
									stringToSearchIn = number.trim().replace(/[^0-9.]/g, '');

									if (
										stringToSearchIn.indexOf(
											input.trim().replace(/[^0-9.]/g, '')
										) !== -1
									) {
										result.push(employee);
									}
								});
							}

							break;

						case 'email':
							stringToSearchIn = employee['email'];

							if (
								stringToSearchIn.toLowerCase().includes(input.toLowerCase())
							) {
								result.push(employee);
							}
							break;

						case 'title':
							stringToSearchIn = employee['title'];

							if (stringToSearchIn)
								switch (input.toLowerCase()) {
									case 'hr':
										array_to_search = searchByDepartments['hr'].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									case 'rm':
										array_to_search = searchByDepartments['rm'].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									case 'pm':
										array_to_search = searchByDepartments['pm'].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									case 'qa':
										array_to_search = searchByDepartments['qa'].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									case 'project':
										array_to_search = searchByDepartments['project'].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									case 'project manager':
										array_to_search = searchByDepartments[
											'project manager'
										].split(' ');

										for ($word in array_to_search) {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(array_to_search[$word])
											) {
												result.push(employee);
											}
										}
										break;

									default:
										if (typeof stringToSearchIn !== 'undefined') {
											if (
												stringToSearchIn
													.toLowerCase()
													.includes(input.toLowerCase())
											) {
												result.push(employee);
											}
										}
										break;
								}

							break;

						default:
							// Preserve from searching in empty fields
							if (employee[field]) {
								stringToSearchIn = employee[field].toLowerCase();
								if (
									stringToSearchIn
										.toLowerCase()
										.indexOf(input.toLowerCase().trim()) !== -1
								) {
									result.push(employee);
								}
							}
					}
					return result;
				});
				return result;
			});
			return [...new Set(result)];
		} else {
			return [];
		}
	} else {
		return employees;
	}
}
