/*
 * @author: Razvan Rauta
 * Date: 27/05/2020
 * Time: 11:59
 */

import React, { useState } from 'react';
import styles from './ProjectsAndDepartments.module.scss';
import noPicture from '../../layout/images/nophoto.png';

const ProjectsAndDepartments = ({
	supervisor,
	handleEmployeeId,
	department,
	projects,
	currentEmployee,
	badges,
	isPortfolioManager,
	portfolio,
	portfolioManagers,
	deputy
}) => {
	const [isProjectManager, setIsProjectManager] = useState(false);
	let projectsOfManager = [];
	projectsOfManager[currentEmployee] = [];

	const hasDeputy = !!deputy;

	const checkIfProjectManger = (currentEmployee, projects = []) => {
		if (currentEmployee && projects) {
			projects.forEach(project => {
				if (typeof project.project_managers !== 'undefined') {
					project.project_managers.forEach(manager => {
						if (manager.email === currentEmployee) {
							if (!isProjectManager) setIsProjectManager(true);
							projectsOfManager[currentEmployee].push(project.project_name);
							projectsOfManager.name = `${manager.first_name} ${manager.last_name}`;
							projectsOfManager.picture = manager.profile_picture || noPicture;
						}
					});
				}
			});
		}
	};
	checkIfProjectManger(currentEmployee, projects);

	const certificatesWithImages = badges.filter(badge => !!badge.path);
	const certificatesWithoutImages = badges.filter(badge => !badge.path);

	return (
		<div className={styles.projectsAndDepartmentsOuterContainer}>
			<div className={styles.projectsAndDepartmentsContainer}>
				{hasDeputy ? (
					<div className={styles.reportsToSection}>
						<h3 className={styles.sectionTitle}>Current deputy</h3>
						<div
							onClick={() => handleEmployeeId(deputy.email)}
							className={`${styles.project} ${styles.employee}`}
						>
							<div className={styles.managerPictureContainer}>
								<img
									className={styles.managerPicture}
									src={deputy.profile_picture?.small || noPicture}
									alt={deputy.email}
								/>
							</div>
							<div className={styles.managerDetailsContainer}>
								<span className={styles.managerName}>
									{`${deputy.first_name} ${deputy.last_name}`}
								</span>
								<div className={styles.projectNameContainer}>
									<span className={styles.departmentName}>
										{deputy.department_name}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				{supervisor ? (
					<div className={styles.reportsToSection}>
						<h3 className={styles.sectionTitle}>Reports To</h3>
						<div
							onClick={() => handleEmployeeId(supervisor.email)}
							className={`${styles.project} ${styles.employee}`}
						>
							<div className={styles.managerPictureContainer}>
								<img
									className={styles.managerPicture}
									src={supervisor.profile_picture?.small || noPicture}
									alt={supervisor.email}
								/>
							</div>
							<div className={styles.managerDetailsContainer}>
								<span className={styles.managerName}>
									{`${supervisor.first_name} ${supervisor.last_name}`}
								</span>
								<div className={styles.projectNameContainer}>
									<span className={styles.managerPosition}>
										{supervisor.title}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{department &&
				department.manager &&
				department.manager.email !== currentEmployee ? (
					<div className={styles.departmentReportingSection}>
						<h3 className={styles.sectionTitle}>Department Reporting</h3>
						<div
							onClick={() => handleEmployeeId(department.manager.email)}
							className={`${styles.project} ${
								department.manager.email !== currentEmployee
									? styles.employee
									: ''
							}`}
						>
							<div className={styles.managerPictureContainer}>
								<img
									className={styles.managerPicture}
									src={department.manager.picture || noPicture}
									alt={department.manager.name}
								/>
							</div>
							<div className={styles.managerDetailsContainer}>
								<span className={styles.managerName}>
									{department.manager.name}
								</span>
								<div className={styles.projectNameContainer}>
									<span className={styles.departmentName}>
										{department.department_name}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{isPortfolioManager ? (
					<div className={styles.projectReportingSection}>
						<h3 className={styles.sectionTitle}>PORTFOLIO MANAGEMENT</h3>
						<div className={styles.projectsOfManager}>
							{portfolio.map(item => (
								<React.Fragment key={item}>
									<span className={styles.projectOfManagerName}>
										{item.code}
									</span>
								</React.Fragment>
							))}
						</div>
					</div>
				) : portfolioManagers.length > 0 ? (
					<div className={styles.projectReportingSection}>
						<h3 className={styles.sectionTitle}>PORTFOLIO REPORTING</h3>
						{portfolioManagers.map(portfolio =>
							portfolio.managers.map(manager => (
								<div
									key={manager.email}
									onClick={() => handleEmployeeId(manager.email)}
									className={`${styles.project} ${
										!isProjectManager ? styles.employee : ''
									}`}
								>
									<div className={styles.managerPictureContainer}>
										<img
											className={styles.managerPicture}
											src={manager.profile_picture?.small || noPicture}
											alt={manager.email}
										/>
									</div>
									<div className={styles.managerDetailsContainer}>
										<span className={styles.managerName}>
											{`${manager.first_name} ${manager.last_name}`}
										</span>
										<div className={styles.projectNameContainer}>
											<span className={styles.projectName}>
												{portfolio.code}
											</span>
										</div>
									</div>
								</div>
							))
						)}
					</div>
				) : (
					''
				)}
				{projects && projects.length ? (
					<div className={styles.projectReportingSection}>
						<h3 className={styles.sectionTitle}>
							{isProjectManager ? 'PROJECT MANAGEMENT' : 'PROJECT REPORTING'}
						</h3>
						{!isProjectManager ? (
							projects.map(project =>
								project.project_managers
									? project.project_managers.map(manager => (
											<div
												key={manager.email}
												onClick={() => handleEmployeeId(manager.email)}
												className={`${styles.project} ${
													!isProjectManager ? styles.employee : ''
												}`}
											>
												<div className={styles.managerPictureContainer}>
													<img
														className={styles.managerPicture}
														src={manager.profile_picture || noPicture}
														alt={manager.email}
													/>
												</div>
												<div className={styles.managerDetailsContainer}>
													<span className={styles.managerName}>
														{`${manager.first_name} ${manager.last_name}`}
													</span>
													<div className={styles.projectNameContainer}>
														<span className={styles.projectName}>
															{project.project_name}
														</span>
													</div>
												</div>
											</div>
									  ))
									: ''
							)
						) : projectsOfManager[currentEmployee].length ? (
							<div className={styles.projectsOfManager}>
								{projectsOfManager[currentEmployee].map(project => (
									<React.Fragment key={project}>
										<span className={styles.projectOfManagerName}>
											{project}
										</span>
									</React.Fragment>
								))}
							</div>
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
			</div>
			<div className={styles.badgesContainer}>
				{!!badges.length && (
					<>
						<h3 className={styles.sectionTitle}>Certifications</h3>
						{!!certificatesWithImages.length && (
							<div className={styles.badges}>
								{certificatesWithImages.map((badge, idx) => (
									<div key={idx} className={styles.imgBx}>
										<img src={badge.path} alt={badge.description} />
									</div>
								))}
							</div>
						)}
						{!!certificatesWithoutImages.length && (
							<div className={styles.badgesWithoutImg}>
								{certificatesWithoutImages.map((badge, idx) => (
									<div key={idx}>{badge.description}</div>
								))}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ProjectsAndDepartments;
