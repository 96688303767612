/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:59
 */

import { all, takeLatest, call, put } from 'redux-saga/effects';
import NewsPostsActionType from './newsPosts.types';
import { fetchPosts_API, fetchToken_API } from '../../services/api';
import {
	fetchJwtTokenFailure,
	fetchJwtTokenSuccess,
	fetchNewsPostsFailure,
	fetchNewsPostsSuccess
} from './newsPosts.actions';

export function* fetchNewsPosts(action) {
	try {
		const postsResponse = yield call(fetchPosts_API, action.payload);
		yield put(fetchNewsPostsSuccess(postsResponse.data));
	} catch (error) {
		yield put(fetchNewsPostsFailure(error));
	}
}

export function* fetchToken() {
	try {
		const tokenResponse = yield call(fetchToken_API);
		yield put(fetchJwtTokenSuccess(tokenResponse.data));
	} catch (error) {
		yield put(fetchJwtTokenFailure(error));
	}
}

export function* onFetchingTokenSuccess() {
	yield takeLatest(NewsPostsActionType.FETCH_JWT_TOKEN_SUCCESS, fetchNewsPosts);
}

export function* onFetchingToken() {
	yield takeLatest(NewsPostsActionType.FETCH_JWT_TOKEN_START, fetchToken);
}
export function* newsPostsSagas() {
	yield all([call(onFetchingToken), call(onFetchingTokenSuccess)]);
}
