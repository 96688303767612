/*
 * @author: Razvan Rauta
 * Date: 03/10/2019
 * Time: 15:05
 */
import React from 'react';
import './Spinner.scss';

const spinner = () => {
	return (
		<div id="loader-container">
			<div className="active" id="loader">
				Loading...
			</div>
		</div>
	);
};

export default spinner;
