import React from 'react';
import styles from './BookCard.module.scss';

import devices from '../../assets/images/otherResources/booking/devices.svg';
import hot_places from '../../assets/images/otherResources/booking/hot_places.svg';

const BookCard = ({ title }) => {
	let deviceLink = 'https://booking.e.klika-tech.com/schedule?category=Devices';
	let desksLink =
		'https://booking.e.klika-tech.com/schedule?category=Hot%20Desks';

	if (window.location.href.includes('qa.')) {
		deviceLink =
			'https://qa.booking.e.klika-tech.com/schedule?category=Devices';
		desksLink =
			'https://qa.booking.e.klika-tech.com/schedule?category=Hot%20Desks';
	}

	const BookBtn = ({ link, icon, title }) => {
		return (
			<a
				href={link}
				className={styles.bookBtn}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={icon} alt={title} className={styles.bookBtnIcon} />
				{title}
			</a>
		);
	};

	return (
		<div className={styles.card}>
			<div className={styles.cardBody}>
				<p className={styles.cardTitle}>
					{title}
					{/*<span className={styles.label}>new</span>*/}
				</p>
				<div className={styles.bookBtnGroup}>
					<BookBtn link={deviceLink} title={'Devices'} icon={devices} />
					<BookBtn link={desksLink} title={'Hot Desks'} icon={hot_places} />
				</div>
			</div>
		</div>
	);
};

export default BookCard;
