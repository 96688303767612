/*
 * @author: Razvan Rauta
 * Date: 13/08/2019
 * Time: 16:35
 */

import React from 'react';
import Card from '../Card/Card';
import './home.scss';
import SearchPage from '../Search/SearchPage';
import SearchForm from '../SearchForm/SearchForm';
// import LayoutFooter from '../../layout/components/LayoutFooter/LayoutFooter';
import { Footer } from 'design-components';
import EmployeesContext from '../Context/EmployeesContext';
import { HOME_RESOURCES } from '../../constants';
import JiraButton from '../JiraButton/JiraButton';
import { EmergencyButton } from '../EmergencyButton';
import { EmergencyModal } from '../EmergencyModal';
import Script from 'react-load-script';
import { GoogleAEvent } from '../../services/GoogleAEvent';
import OtherResources from '../../layout/components/OtherResources/OtherResources';
import BookCard from '../BookCard/BookCard';

class Home extends React.Component {
	static contextType = EmployeesContext;

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			search: null,
			employeeId: null,
			loading: true,
			showEmergencyModal: false
		};
	}

	componentDidMount() {
		if (this.checkSearch()) {
			this.setState(
				{
					search: this.checkSearch().search,
					employeeId: this.checkSearch().employeeId
				},
				() => this.context.handleModal(true)
			);
		}

		window.addEventListener(
			'orientationchange',
			this.handleOrientationChange,
			false
		);
	}

	componentWillUnmount() {
		window.removeEventListener(
			'orientationchange',
			this.handleOrientationChange,
			false
		);
	}

	sendEventToGoogle = (event, name, location) => {
		GoogleAEvent(event, name, location);
	};

	handleOrientationChange = () => {
		if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			window.location.reload();
		}
	};

	checkSearch = () => {
		let val;
		if (
			window.location.search &&
			window.location.search.includes('?search=') &&
			window.location.search.replace('?search=', '').length > 1
		) {
			val = {
				search: decodeURIComponent(
					window.location.search.replace('?search=', '')
				),
				employeeId: null,
				modal: null
			};
		} else if (
			window.location.search &&
			window.location.search.includes('?employeeId=') &&
			window.location.search.replace('?employeeId=', '').length > 0
		) {
			val = {
				search: null,
				employeeId: window.location.search.replace('?employeeId=', ''),
				modal: null
			};
		} else if (
			window.location.search &&
			window.location.search.includes('?employees')
		) {
			val = { search: null, employeeId: null, modal: true };
		}

		return val;
	};

	handleModal = e => {
		e.preventDefault();
		this.context.handleModal(true);
		this.sendEventToGoogle('CLICK', 'Search input', 'START_PAGE');
	};

	handleButton = e => {
		e.preventDefault();
		this.setState({ search: null, employeeId: null }, () =>
			this.context.handleModal(!this.context.showModal)
		);
		document.getElementById('searchInput').value = '';
	};

	handleEmergencyModalClose = () =>
		this.setState({ showEmergencyModal: false });

	handleEmergencyModalOpen = () => this.setState({ showEmergencyModal: true });

	render() {
		const { showModal } = this.context;
		const { showEmergencyModal } = this.state;

		const renderTitles = card => {
			switch (card.cardType) {
				case 'blue':
				case 'white':
					return (
						<Card
							key={card.name}
							title={card.name}
							icon={card.icon}
							url={card.url}
							description={card.description}
							classNm={card.class}
							handleClick={this.sendEventToGoogle}
							handleModal={this.handleModal}
							classList={card.classList}
						/>
					);
				case 'booking':
					return <BookCard key={card.name} title={card.name} />;
				case 'otherResources':
					return (
						<div
							key="other-resources"
							className="otherResourcesTileContainer otherResources"
						>
							<p className="otherResourcesTitle">OTHER RESOURCES</p>
							<OtherResources
								handleClick={this.sendEventToGoogle}
								customClass="gridTile"
							/>
						</div>
					);
				default:
					return '';
			}
		};

		return (
			<>
				{showEmergencyModal && (
					<EmergencyModal handleClose={this.handleEmergencyModalClose} />
				)}
				<div
					className={`homeContainer ${showModal ? 'fullHeight' : ''}`}
					id="homeContainer"
				>
					<Script
						url="https://klika-tech.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/j23eav/b/20/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=ada79a74"
						onCreate={() => this.setState(state => (state.loading = true))}
						onError={() => this.setState(state => (state.loading = false))}
						onLoad={() => this.setState(state => (state.loading = false))}
					/>

					<SearchForm
						open={open}
						showModal={showModal}
						handleModal={this.handleModal}
						handleButton={this.handleButton}
					/>
					{showModal && (
						<SearchPage
							open={showModal}
							search={this.state.search}
							employeeId={this.state.employeeId}
							handleButton={this.handleButton}
						/>
					)}
					{!showModal && (
						<div className="tiles-container">
							{HOME_RESOURCES.map(card => renderTitles(card))}
						</div>
					)}
					{!this.state.loading && (
						<JiraButton handleClick={this.sendEventToGoogle} />
					)}
					{!this.state.loading && (
						<EmergencyButton handleClick={this.handleEmergencyModalOpen} />
					)}
					<Footer origin={'KLIKA TEAM'} />
				</div>
			</>
		);
	}
}

export default Home;
