import React from 'react';

import { Modal } from '../Modal';
import { GoogleAEvent } from '../../services/GoogleAEvent';
import { HELP_DESK, RESOURCES, OFFICES } from '../../constants';

import styles from './EmergencyModal.module.scss';

export const EmergencyModal = ({ handleClose }) => {
	const renderRow = (fromIndex, toIndex) => {
		return OFFICES.map(({ name, phone, ext }, index) => {
			if (index >= fromIndex && index < toIndex) {
				return (
					<div key={`${name}-${ext}`} className={styles.Office}>
						<div className={styles.OfficeName}>{name}</div>
						<a href={`tel:${phone}`} className={styles.OfficePhoneLink}>
							{phone}
						</a>
						<div className={styles.OfficeExt}>{ext}</div>
					</div>
				);
			}
		});
	};

	const renderOffices = () => (
		<div className={styles.OfficePhoneRowsContainer}>
			<div className={styles.OfficePhoneRows}>{renderRow(0, 2)}</div>
			<div className={styles.OfficePhoneRows}>{renderRow(2, 5)}</div>
		</div>
	);

	const getHelpDeskData = () =>
		RESOURCES.find(resource => resource.name === HELP_DESK);

	const helpDeskData = getHelpDeskData();

	const handleButtonClick = () =>
		GoogleAEvent(
			'CLICK',
			`Header link to ${helpDeskData.name ?? HELP_DESK}`,
			'START_PAGE'
		);

	return (
		<Modal handleClose={handleClose}>
			<div className={styles.Header}>IT Support</div>
			<div className={styles.Content}>
				<span>
					Please use <b>help-desk</b> in case of any problems.
					<br />
					Your issue can be resolved in 24h.
				</span>
				<button className={styles.HelpDeskButton}>
					<a
						onClick={handleButtonClick}
						href={helpDeskData && helpDeskData.url}
						className={styles.HelpDeskLink}
					>
						Go to Help-Desk
					</a>
				</button>
				<span>
					or connect with us via direct phone If you have an urgent case:
				</span>
				<div className={styles.Offices}>{renderOffices()}</div>
			</div>
		</Modal>
	);
};
