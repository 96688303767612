import React from 'react';
import './error.sass';
import image from '../../layout/images/403/403.png';

const ForbiddenPage = () => (
	<div className="errorPage">
		<div className="notFoundContainer">
			<img className="image" src={image} alt="not found" />
			<div className="message">
				{
					"You don't have an active contract and can't access this page. Please contact IT Department"
				}
			</div>
		</div>
	</div>
);
export default ForbiddenPage;
