/*
 * @author: Razvan Rauta
 * Date: 12/11/2019
 * Time: 12:40
 */

import React from 'react';

const SearchForm = props => {
	const { open, showModal } = props;
	let formStyles = ['searchForm'];
	if (open) {
		formStyles.push('OpenDrawer');
	}
	if (showModal) {
		formStyles.push('trans');
	}

	return (
		<div id="searchForm" className={formStyles.join(' ')}>
			<form>
				<input
					autoComplete="off"
					type="text"
					id="searchInput"
					placeholder="Search for a talent"
					onClick={props.handleModal}
				/>
				<button
					id="closeButton"
					onClick={props.handleButton}
					className={props.showModal ? 'closeSearchButton' : 'searchButton'}
				/>
			</form>
		</div>
	);
};

export default SearchForm;
