import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './normalize.css';
import './index.scss';

import App from './App';
import { CookiesProvider } from 'react-cookie';

const app = (
	<Provider store={store}>
		<CookiesProvider>
			<App />
		</CookiesProvider>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));
