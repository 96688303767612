/*
 * @author: Razvan Rauta
 * Date: 14/08/2019
 * Time: 14:24
 */

import React from 'react';
import styles from './Card.module.scss';

const card = ({
	url,
	title,
	classNm,
	icon,
	description,
	handleClick,
	handleModal,
	classList
}) => {
	if (classNm && classNm === 'employees') {
		return (
			<button className={styles.employees} onClick={handleModal}>
				<div className={styles.card}>
					<img className={styles.cardIcon} src={icon} alt="icon" />
					<div className={styles.cardBody}>
						<p className={styles.cardTitle}>{title}</p>
						<p className={styles.cardDescription}>{description}</p>
					</div>
				</div>
			</button>
		);
	}

	return (
		<a
			href={url}
			target={classList && classList.includes('target-blank') ? '_blank' : ''}
			rel="noopener noreferrer"
			onClick={() =>
				handleClick('CLICK', `Card link to "${title}" `, 'START_PAGE')
			}
			className={`${styles.cardLink} ${styles[classNm] || ''} ${
				classList
					? classList
							.map(class_name => {
								return styles[class_name];
							})
							.join(' ')
					: ''
			}`}
		>
			<div className={styles.card}>
				<img className={styles.cardIcon} src={icon} alt="icon" />
				<div className={styles.cardBody}>
					<p className={styles.cardTitle}>{title}</p>
					<p className={styles.cardDescription}>{description}</p>
				</div>
			</div>
		</a>
	);
};

export default card;
