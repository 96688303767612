/*
 * @author: Razvan Rauta
 * Date: 13/08/2019
 * Time: 16:32
 */

import React from 'react';
import Layout from './layout/components/Layout/Layout';
import styles from './layout/components/Layout/layout.module.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { isIE } from 'react-device-detect';
import chrome from './layout/images/chromeIcon.png';
import fireFox from './layout/images/firefoxIcon.png';
import Home from './components/Home/Home';
import axios from 'axios';
import config from './config';
import ForbiddenPage from './components/403/error';
import Error from './components/404/error';
import { initGA, PageView, GoogleAEvent } from './services/GoogleAEvent';

const isProduction = process.env.NODE_ENV !== 'development';

class App extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			showSideDrawer: false,
			available: false,
			showModal: false,
			loading: false,
			isForbiddenPageAvailable: false,
			profileObj: {
				fullName: '',
				email: '',
				avatarPath: '',
				title: '',
				ruTitle: ''
			}
		};
	}

	componentDidMount() {
		axios.defaults.baseURL = config.baseUrl;
		axios.defaults.withCredentials = true;

		if (window.location.port === '3000' && !isProduction) {
			window.location.href = 'https://start.klika-tech.com';
		}

		initGA(config.trackingCode);
		PageView();
		GoogleAEvent(
			'SCREEN',
			`Screen size ${window.innerWidth} x ${window.innerHeight}`,
			'START_PAGE'
		);

		this.setState({ loading: true });

		axios
			.get('/userinfo', {})
			.then(response => {
				let eMail = response.data.email.split('@')[1];

				if (eMail && eMail === 'klika-tech.com') {
					this.setState(
						{
							profileObj: {
								fullName:
									response.data.firstName && response.data.lastName
										? `${response.data.firstName} ${response.data.lastName}`
										: '',
								avatarPath: response.data.profilePicture
									? response.data.profilePicture
									: '',
								email: response.data.email || null,
								title: response.data.title.title || null,
								ruTitle: response.data.title.ruTitle || null
							},
							available: true,
							loading: false
						},
						() => GoogleAEvent('Login', `User logged in`, 'START_PAGE')
					);
				}
			})
			.catch(error => {
				this.setState({ loading: false });

				switch (error.response.status) {
					case 401:
						window.location.href = `${
							error.response.data.authLoginUrl
						}?redirect_uri=${encodeURIComponent(window.location.href)}`;
						break;
					case 403:
						this.setState({
							available: true
						});
						this.setState({
							isForbiddenPageAvailable: true
						});
						if (window.location.pathname !== '/403') {
							window.location.href = '/403';
						}
						break;
					case 502:
						this.showMessage('Server is down!');
						break;
					default:
						this.showMessage(error.message);
				}
			});
	}

	sideDrawerClosedHandler = () => {
		this.setState({ showSideDrawer: false });

		document.body.style.overflowY = 'auto';
	};

	sideDrawerToggleHandler = () => {
		this.setState(prevState => {
			return { showSideDrawer: !prevState.showSideDrawer };
		});

		//prevent scrolling on mobile
		if (!this.state.showSideDrawer) {
			document.body.style.overflowY = 'hidden';
			document.body.addEventListener('touchmove', function(e) {
				e.preventDefault();
			});
		} else {
			document.body.style.overflowY = 'auto';
			document.body.removeEventListener('touchmove', function(e) {
				e.preventDefault();
			});
		}
	};

	loginHandler = () => {
		this.setState({
			loading: true
		});
	};

	showMessage = msg => {
		console.log(
			'%c %s %s %s',
			'color: red; font-weight:bold;text-decoration:underline;',
			'--',
			msg,
			'--\n'
		);
	};

	render() {
		if (isIE)
			return (
				<div className="text-center IE">
					<h1>
						Hi!
						<br /> Internet Explorer is not supported! :(
					</h1>
					<br />
					<h3>It&apos;s time for upgrade!</h3>
					<br />
					<ul className="tileMe">
						<li>
							<a className="tile" href="https://www.google.com/chrome/">
								<img className="ieIcon" src={chrome} alt="Google Chrome Icon" />
								Download Google Chrome
							</a>
						</li>
						<li>
							<a
								className="tile"
								href="https://www.mozilla.org/en-US/firefox/new/"
							>
								<img
									className="ieIcon"
									src={fireFox}
									alt="Mozilla Firefox Icon"
								/>
								Download Mozilla Firefox
							</a>
						</li>
					</ul>
				</div>
			);
		return (
			<BrowserRouter>
				<Layout
					className={styles.layout}
					open={this.state.showSideDrawer}
					closed={this.sideDrawerClosedHandler}
					drawerToggleClicked={this.sideDrawerToggleHandler}
					loginHandler={this.loginHandler}
					available={this.state.available}
					profileObj={this.state.profileObj}
					loading={this.state.loading}
				>
					<Switch>
						<Route
							exact
							path="/"
							render={() => <Home open={this.state.showSideDrawer} />}
						/>
						{this.state.isForbiddenPageAvailable && (
							<Route path="/403" component={ForbiddenPage} />
						)}
						<Route component={Error} />
					</Switch>
				</Layout>
			</BrowserRouter>
		);
	}
}

export default withCookies(App);
