/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:59
 */

import NewsPostsActionType from './newsPosts.types';

export const fetchJwtTokenStart = () => ({
	type: NewsPostsActionType.FETCH_JWT_TOKEN_START
});

export const fetchJwtTokenSuccess = token => ({
	type: NewsPostsActionType.FETCH_JWT_TOKEN_SUCCESS,
	payload: token.token
});

export const fetchJwtTokenFailure = error => ({
	type: NewsPostsActionType.FETCH_JWT_TOKEN_FAILURE,
	payload: error
});

export const fetchNewsPostsStart = token => ({
	type: NewsPostsActionType.FETCH_POSTS_START,
	token
});

export const fetchNewsPostsSuccess = data => ({
	type: NewsPostsActionType.FETCH_POSTS_SUCCESS,
	payload: data
});

export const fetchNewsPostsFailure = error => ({
	type: NewsPostsActionType.FETCH_POSTS_FAILURE,
	payload: error
});
