/*
 * @author: Razvan Rauta
 * Date: 13/08/2019
 * Time: 16:28
 */

import React from 'react';
// import LayoutHeader from '../LayoutHeader/LayoutHeader';
import styles from './layout.module.scss';
// import SideDrawer from '../SideDrawer/SideDrawer';
import { Header } from 'design-components';
import { EmployeesProvider } from '../../../components/Context/EmployeesContext';
import Spinner from '../Spinner/Spinner';

class Layout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};
	}

	handleModal = show => {
		this.setState({ showModal: show });
	};

	render() {
		const {
			open,
			closed,
			drawerToggleClicked,
			// loginHandler,
			available,
			loading
		} = this.props;

		const children = React.Children.map(this.props.children, child => {
			return React.cloneElement(child, {
				open,
				closed,
				drawerToggleClicked
			});
		});
		return available && !loading ? (
			<EmployeesProvider
				value={{
					showModal: this.state.showModal,
					handleModal: this.handleModal
				}}
			>
				<div className={styles.layout}>
					<Header
						origin={'KLIKA TEAM'}
						user={this.props.profileObj}
						employeesShowModal={this.state.showModal}
						employeesHandleModal={this.handleModal}
					/>
					{this.props.available ? (
						<main className={open ? styles.open : ''}>{children}</main>
					) : (
						''
					)}
				</div>
			</EmployeesProvider>
		) : (
			<Spinner />
		);
	}
}

export default Layout;
