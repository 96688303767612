/*
 * @author: Razvan Rauta
 * Date: 02/10/2019
 * Time: 13:04
 */

import React from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import Employee from '../EmployeeCard/Employee';
import './SearchPage.scss';
import filterEmployees from './FilterResults';
import noPicture from '../../layout/images/nophoto.png';
import searchImage from '../../layout/images/user_friendly_search.png';
import Spinner from '../../layout/components/Spinner/Spinner';
import ScrollToTop from 'react-scroll-up';
import ScrollToTopLandscape from '../../layout/components/ScrollToTop/ScrollToTopLandscape';
import { GoogleAEvent } from '../../services/GoogleAEvent';

class SearchPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			query: '',
			data: null,
			searchString: null,
			responseData: null,
			loading: false,
			scrollLandscape: false,
			previous: ''
		};

		this.handleInputChange.bind(this);
		this.handleKeyPress.bind(this);
		this.handleCloseButton.bind(this);
		this.escFunction.bind(this);
		this.handleOrientationChange.bind(this);
		this.parseQueryString.bind(this);
		this.hasEmail.bind(this);
		this.checkAndVerifySearch.bind(this);
	}

	componentDidMount() {
		document
			.getElementById('searchInput')
			.addEventListener('input', this.handleInputChange);
		document
			.getElementById('searchInput')
			.addEventListener('keydown', this.handleKeyPress);
		document
			.getElementById('closeButton')
			.addEventListener('click', this.handleCloseButton);
		document.addEventListener('keydown', this.escFunction, false);
		window.addEventListener(
			'orientationchange',
			this.handleOrientationChange,
			false
		);
		window.addEventListener('popstate', this.handlePopState);

		if (this.props.search) this.handleSearchUrl(this.props.search);
		if (this.props.employeeId) this.handleEmployeeId(this.props.employeeId);

		const orientation = window.screen.orientation;

		if (typeof orientation !== 'undefined') {
			if (orientation.angle === -90 || orientation.angle === 90) {
				this.setState({ scrollLandscape: true });
			}
		}
	}

	componentWillUnmount() {
		document
			.getElementById('searchInput')
			.removeEventListener('input', this.handleInputChange);
		document
			.getElementById('searchInput')
			.removeEventListener('keydown', this.handleKeyPress);
		document
			.getElementById('closeButton')
			.removeEventListener('click', this.handleCloseButton);
		document.removeEventListener('keydown', this.escFunction, false);
		window.removeEventListener(
			'orientationchange',
			this.handleOrientationChange,
			false
		);
		window.removeEventListener('popstate', this.handlePopState);
	}

	escFunction = event => {
		const { handleButton } = this.props;
		if (event.keyCode === 27) {
			document.getElementById('searchInput').blur();
			this.handleCloseButton();
			handleButton(event);
		}
	};

	sendEventToGoogle = (event, name, location) => {
		GoogleAEvent(event, name, location);
	};

	handlePopState = () => {
		const parsedQuery = this.parseQueryString(this.props.location.search);

		if (this.hasEmail(parsedQuery)) {
			this.getDataById(parsedQuery).catch(error => {
				console.log(error);
			});
		} else {
			this.setState({ query: parsedQuery });
			this.checkAndVerifySearch(parsedQuery);
		}
	};

	parseQueryString = query => {
		return query.replace(/^\?.+=/, '');
	};

	hasEmail = word => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(word);
	};

	verifySearch = debounce(val => {
		var phoneRegEx = new RegExp(
			/(\+[0-9]{3}\s?-?\(?[0-9]{2}\)?\s?-?)(([0-9](\s|-)?)*)/,
			'i'
		);

		let formatStr = val;

		if (formatStr.match(phoneRegEx)) formatStr = formatStr.match(phoneRegEx)[2];
		if (!isNaN(parseInt(formatStr))) {
			var strLen = parseInt(formatStr).toString().length;
			if (strLen > 3 && strLen <= 5)
				formatStr = formatStr.slice(0, 3) + ' ' + formatStr.slice(3);
			if (strLen > 5 && strLen <= 7)
				formatStr =
					formatStr.slice(0, 3) +
					' ' +
					formatStr.slice(3, 5) +
					' ' +
					formatStr.slice(5);
		}

		this.getData(formatStr).then(() => {
			this.setState(
				{
					loading: false
				},
				() => {
					this.filterArray();
				}
			);
		});
	}, 900);

	handleOrientationChange = () => {
		const orientation = window.screen.orientation;
		if (typeof orientation !== 'undefined') {
			if (orientation.angle === -90 || orientation.angle === 90) {
				this.setState({ scrollLandscape: true });
			}
		}
	};

	handleCloseButton = () => {
		this.setState({
			data: null,
			responseData: null,
			query: ''
		});
		window.history.replaceState(null, null, `/`);
	};

	handleInputChange = event => {
		let val = event.target.value ? event.target.value : '';
		this.setState({ query: val });

		window.history.replaceState(null, null, `/?search=${val}`);

		this.checkAndVerifySearch(val);
	};

	handleSearchUrl = search => {
		this.setState({ query: search });
		document.getElementById('searchInput').value = search;

		this.checkAndVerifySearch(search);
	};

	checkAndVerifySearch = search => {
		if (
			search.length > 2 ||
			['qa', 'pm', 'rm', 'hr'].indexOf(search.toLowerCase()) !== -1
		) {
			this.setState({ loading: true });
			this.verifySearch(search);
		}
	};

	handleKeyPress = event => {
		if (event.key === 'Enter') event.preventDefault();

		if (event.key === 'Backspace' || event.key === 'Delete') {
			this.setState(
				{
					query: '',
					data: null,
					searchString: null
				},
				() => {
					if (!document.getElementById('searchInput').value) {
						this.setState({
							responseData: null
						});
					}
				}
			);
		}
	};

	getData = async val => {
		await axios
			.get(`/search`, {
				params: {
					for: val.trim()
				}
			})
			.then(response => {
				this.setState({
					data: response.data
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	filterArray = () => {
		let searchString = this.state.query;
		let responseData = this.state.data;

		if (searchString.length > 0 && responseData) {
			responseData = filterEmployees(responseData, searchString);
			this.setState(
				{
					responseData
				},
				() =>
					this.sendEventToGoogle(
						'USER',
						`Searched for "${searchString}"`,
						'EMPLOYEES_DIRECTORY'
					)
			);
		}
	};

	getDataById = async val => {
		if (val) {
			this.setState({ loading: true });

			await axios
				.get(`/employee`, {
					params: {
						employeeId: val.trim()
					}
				})
				.then(response => {
					this.setState({
						responseData: response.data,
						loading: false
					});
					if (response.data && response.data.length > 0) {
						this.setState({ query: 'employeeId' });
					} else {
						this.setState({ query: val });
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	};

	handleEmployeeId = employeeId => {
		const { history } = this.props;
		this.setState({ previous: window.location.search });
		this.getDataById(employeeId).catch(error => {
			console.log(error);
		});
		if (history)
			history.push({
				pathname: '/',
				search: `?employeeId=${employeeId}`
			});
		document.getElementById('searchInput').value = '';
		window.scrollTo(0, 0);
	};

	render() {
		const { responseData, loading, scrollLandscape } = this.state;
		let message = '';
		let show = this.state.responseData ? 'results' : 'isHidden';
		let query = this.state.query;

		if (
			(responseData &&
				responseData.length === 0 &&
				query.length > 2 &&
				!loading) ||
			(responseData &&
				responseData.length === 0 &&
				['qa', 'pm', 'rm', 'hr'].indexOf(query.toLowerCase()) !== -1)
		) {
			message = (
				<div id="no-results-found-message">
					<p>
						No results were found for <b>{query}</b>
					</p>
				</div>
			);
		}

		return (
			<div className="searchContainer" id="searchContainer">
				<div id="results" className={show}>
					{responseData
						? this.state.responseData.map(i => (
								<Employee
									key={i.id}
									id={i.id}
									profilePicture={
										i.profile_picture ? i.profile_picture.small : noPicture
									}
									title={i.title}
									firstName={i.first_name}
									lastName={i.last_name}
									email={i.email}
									phoneNumbers={i.phone_numbers}
									projectManager={i.project_manager}
									projects={i.projects}
									supervisor={i.supervisor}
									url={`?employeeId=${i.id}`}
									floorSection={i.floor_section}
									officeLocation={i.office_location}
									city={i.city}
									query={query}
									handleEmployeeId={this.handleEmployeeId}
									department={i.department}
									leave={i.leave}
									badges={i.badges || []}
									isPortfolioManager={i.isPortfolioManager}
									portfolio={i.portfolio}
									portfolioManagers={i.portfolio_managers}
									deputy={i.deputy}
								/>
						  ))
						: ''}
				</div>
				{this.state.loading && <Spinner />}
				{!responseData && query.length < 3 && (
					<div id="user-friendly-search">
						<img src={searchImage} alt="friendlySearch" />
						<p>
							To search for a talent, please, start typing. The minimum number
							of characters required is 3.
						</p>
						<p>
							When searching by position you can use short strings like
							&quot;dev&quot;, &quot;qa&quot;, &quot;pm&quot;, &quot;rm&quot; or
							&quot;hr&quot; to get the relevant search results.
						</p>
						<br />
						<b>Search will be carried out by:</b>
						<ul>
							<li>Name</li>
							<li>Position</li>
							<li>Email</li>
							<li>Phone number</li>
						</ul>
					</div>
				)}
				{message}
				{!scrollLandscape && (
					<ScrollToTop showUnder={500} duration={500}>
						<span id="backToTop" />
					</ScrollToTop>
				)}
				{scrollLandscape && responseData && responseData.length > 5 && (
					<ScrollToTopLandscape scrollStepInPx="50" delayInMs="16.66" />
				)}
			</div>
		);
	}
}

export default withRouter(SearchPage);
