/*
 * @author: Razvan Rauta
 * Date: 20/01/2020
 * Time: 11:30
 */

import React from 'react';
import styles from './JiraButton.module.scss';

const JiraButton = ({ handleClick }) => (
	<button
		id="jira-issue-button"
		className={styles.jiraButton}
		onClick={() => handleClick('CLICK', 'JIRA FEEDBACK button ', 'START_PAGE')}
	>
		<span>FEEDBACK</span>
	</button>
);

export default JiraButton;
