/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:53
 */
import { all, call } from 'redux-saga/effects';
import { newsPostsSagas } from './newsPosts/newsPosts.sagas';

export default function* rootSaga() {
	yield all([call(newsPostsSagas)]);
}
