/*
 * @author: Razvan Rauta
 * Date: 18/03/2020
 * Time: 12:22
 */

import ReactGA from 'react-ga';

export const GoogleAEvent = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label
	});
};

export const initGA = trackingID => {
	ReactGA.initialize(trackingID);
};

export const PageView = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
};
