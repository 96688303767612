/*
 * @author: Razvan Rauta
 * Date: 16/08/2019
 * Time: 11:25
 */

const dev = {
	baseUrl: 'https://start.klika-tech.com/web/app_dev.php',
	trackingCode: '1234567',
	key: 'ud75G4r3&5$VVPmn9Pa38v3Ea&G',
	news: {
		token_url: 'https://news.e.klika-tech.com/wp-json/jwt-auth/v1/token',
		username: 'REST_API',
		password: 'R#Rn$UXNiffnDOGYcWJ!dNZL'
	},
	officeLink: 'https://qa.office.e.klika-tech.com'
};

const prod = {
	baseUrl: 'https://e.klika-tech.com/api/app.php',
	trackingCode: 'UA-37640521-2',
	key: 'ud75G4r3&5$VVPmn9Pa38v3Ea&G',
	news: {
		token_url: 'https://news.e.klika-tech.com/wp-json/jwt-auth/v1/token',
		username: 'REST_API',
		password: 'R#Rn$UXNiffnDOGYcWJ!dNZL'
	},
	officeLink: 'https://office.e.klika-tech.com'
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default {
	MAX_ATTACHMENT_SIZE: 5000000,
	...config
};
