/**
 * @author Razvan Rauta
 * 19/02/2020
 * 16:25
 */

import axios from 'axios';
import config from '../config';

export const fetchToken_API = () => {
	const { username, password, token_url } = config.news;

	return axios.post(token_url, { username, password });
};

export const fetchPosts_API = async token => {
	return axios.get('/newsposts', {
		params: {
			token
		}
	});
};
