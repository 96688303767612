/*
 * @author: Razvan Rauta
 * Date: 30/06/2020
 * Time: 12:04
 */

import React from 'react';
import uuid from 'uuid';
import styles from './OtherResources.module.scss';
import { OTHER_RESOURCES } from '../../../constants';

const OtherResources = ({ handleClick, customClass }) => (
	<div
		className={`${styles.otherResourcesContainer} ${styles[customClass] || ''}`}
	>
		{OTHER_RESOURCES.map(resource => (
			<a
				className={styles.link}
				key={uuid.v1()}
				href={resource.url}
				target="_blank"
				rel="noopener noreferrer"
				onClick={() =>
					handleClick('CLICK', `Icon '${resource.name || ''}' `, 'START_PAGE')
				}
			>
				<div className={styles.resourceCard}>
					<img
						className={`${styles.resourceIcon} ${styles[resource.class] || ''}`}
						src={resource.icon}
						alt={`${resource.name} icon`}
					/>
					<p className={`${styles.resourceText}`}>{resource.name}</p>
				</div>
			</a>
		))}
	</div>
);

export default OtherResources;
