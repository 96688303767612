/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:53
 */

import { combineReducers } from 'redux';
// import storage from 'redux-persist/lib/storage';
import newsPostsReducer from './newsPosts/newsPosts.reducer';

const rootReducer = combineReducers({
	newsPosts: newsPostsReducer
});

export default rootReducer;
