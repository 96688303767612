/*
 * @author: Razvan Rauta
 * Date: 15/10/2019
 * Time: 11:45
 */

import handbook from './assets/images/homeTiles/Handbook.svg';
import office from './assets/images/homeTiles/Office-Map.svg';
import helpDesk from './assets/images/homeTiles/Help-Desk.svg';
import news from './assets/images/homeTiles/News.svg';
import policy from './assets/images/homeTiles/Policy.svg';
import timeOff from './assets/images/homeTiles/TIme-off.svg';
import employees from './assets/images/homeTiles/Employees.svg';
import gitlab from './assets/images/otherResources/gitlab.svg';
import office365 from './assets/images/otherResources/Office365.svg';
import jetbrains from './assets/images/otherResources/JetBrains.svg';
import eset from './assets/images/otherResources/ESET_logo.svg';
import orgStructure from './assets/images/homeTiles/Org_chart_thumb.jpg';

export const HELP_DESK = 'HELP DESK';

export const RESOURCES = [
	{ name: 'KLIKA TEAM', url: '', class: 'employees' },
	{
		name: 'HANDBOOK',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/overview?homepageId=4003659955',
		class: ''
	},
	{
		name: 'TIME OFF',
		url: 'http://time-off.e.klika-tech.com/',
		class: 'hideFromMobile'
	},
	{
		name: 'BOOKING',
		url: 'https://booking.e.klika-tech.com',
		label: 'new',
		class: ''
	},
	{
		name: 'OFFICE MAP',
		url: 'https://office.e.klika-tech.com',
		class: 'hideFromMobile'
	},
	{
		name: HELP_DESK,
		url: 'https://klika-tech.atlassian.net/servicedesk/customer/portals',
		class: ''
	},
	{ name: 'NEWS', url: 'https://news.e.klika-tech.com', class: '' },
	{
		name: 'POLICY',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKPOL/overview?homepageId=4042457265?homepageId=4042457265',
		class: ''
	}
];

export const RESOURCES_MOBILE = [
	{ name: 'KLIKA TEAM', url: '', class: 'employees' },
	{
		name: 'HANDBOOK',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/overview?homepageId=4003659955',
		class: ''
	},
	{
		name: 'BOOKING',
		url: 'https://booking.e.klika-tech.com',
		label: 'new',
		class: ''
	},
	{
		name: 'HELP DESK',
		url: 'https://klika-tech.atlassian.net/servicedesk/customer/portals',
		class: ''
	},
	{ name: 'NEWS', url: 'https://news.e.klika-tech.com', class: '' },
	{
		name: 'POLICY',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKPOL/overview?homepageId=4042457265?homepageId=4042457265',
		class: ''
	}
];

export const OTHER_RESOURCES = [
	{
		name: 'JetBrains',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/pages/4019814455/Corporate+Software+and+Licenses#Integrated-Development-Environment-(IDE)',
		icon: jetbrains,
		class: 'jetbrains'
	},
	{
		name: 'GitLab',
		url: 'https://gitlab.klika-tech.com',
		icon: gitlab,
		class: 'gitlab'
	},
	{
		name: 'Office365',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/pages/4019814455/Corporate+Software+and+Licenses#Office-Tools',
		icon: office365,
		class: 'office365'
	},
	{
		name: 'NOD32',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/pages/4019814455/Corporate+Software+and+Licenses#Antivirus',
		icon: eset,
		class: 'eset'
	}
];

export const OFFICES = [
	{
		name: 'USA',
		phone: '+1 917 789 11 55',
		ext: 'ext. 2050'
	},
	{
		name: 'Germany',
		phone: '+49 1573 599 71 55',
		ext: 'ext. 2050'
	},
	{
		name: 'Poland',
		phone: '+4 871 702 10 79',
		ext: 'ext. 2050'
	},
	{
		name: 'Belarus',
		phone: '+375 29 632 01 90',
		ext: 'ext. 2050'
	},
	{
		name: 'Kazakhstan',
		phone: '+7 727 312 32 97',
		ext: 'ext. 2050'
	}
];

export const HOME_RESOURCES = [
	{
		name: 'KLIKA TEAM',
		url: '',
		icon: employees,
		description: 'Contact details, position, projects, supervisor',
		class: 'employees',
		cardType: 'blue',
		classList: ['employees']
	},
	{
		name: 'HANDBOOK',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/overview?homepageId=4003659955',
		icon: handbook,
		description: 'Working conditions and talent support info',
		class: 'handbook',
		cardType: 'blue',
		classList: ['handbook']
	},
	{
		name: 'TIME OFF',
		url: 'https://time-off.e.klika-tech.com',
		icon: timeOff,
		description: 'Working conditions and talent support info',
		class: 'timeOff',
		cardType: 'blue',
		classList: ['timeOff']
	},
	{
		name: 'NEWS',
		url: 'https://news.e.klika-tech.com',
		icon: news,
		description: 'Stay on top of our internal corporate life',
		class: 'news',
		cardType: 'white',
		classList: ['cardTextInRow', 'target-blank', 'news']
	},
	{
		name: 'OFFICE MAP',
		url: 'https://office.e.klika-tech.com',
		icon: office,
		description: 'A map to help you locate the right people',
		class: 'office',
		cardType: 'white',
		classList: ['cardTextInRow', 'target-blank', 'office']
	},
	{
		name: 'Booking system',
		class: 'booking',
		cardType: 'booking',
		classList: ['cardTextInRow', 'target-blank', 'booking']
	},
	{
		name: 'OTHER RESOURCES',
		url: '',
		icon: '',
		description: '',
		class: 'otherResources',
		cardType: 'otherResources'
	},

	{
		name: 'HELP DESK',
		url: 'https://klika-tech.atlassian.net/servicedesk/customer/portals',
		icon: helpDesk,
		description: 'Assistance and support for incoming queries',
		class: 'employee',
		cardType: 'white',
		classList: ['cardTextInRow', 'target-blank', 'helpdesk']
	},
	{
		name: 'Policy',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKPOL/overview?homepageId=4042457265',
		icon: policy,
		description: 'Guide on how a talent should perform its work',
		class: 'policy',
		cardType: 'white',
		classList: ['cardTextInRow', 'target-blank', 'policy']
	},
	{
		name: 'COMPANY STRUCTURE',
		url:
			'https://klika-tech.atlassian.net/wiki/spaces/KLKHB/pages/4011360298/Company+Structure',
		icon: orgStructure,
		description: 'The orgranizational structure of the company',
		class: '',
		cardType: 'white',
		classList: ['companyStructure', 'cardTextInRow', 'target-blank', 'company']
	}
];
