/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:59
 */

import NewsPostsActionType from './newsPosts.types';

const INITIAL_STATE = {
	token: null,
	isLoading: false,
	errorMessage: undefined,
	posts: null
};

const newsPostsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NewsPostsActionType.FETCH_POSTS_START:
		case NewsPostsActionType.FETCH_JWT_TOKEN_START:
			return {
				...state,
				isLoading: true,
				errorMessage: undefined
			};

		case NewsPostsActionType.FETCH_POSTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				posts: action.payload
			};

		case NewsPostsActionType.FETCH_JWT_TOKEN_SUCCESS:
			return {
				...state,
				isLoading: false,
				token: action.payload
			};

		case NewsPostsActionType.FETCH_JWT_TOKEN_FAILURE:
		case NewsPostsActionType.FETCH_POSTS_FAILURE:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload
			};

		default:
			return state;
	}
};

export default newsPostsReducer;
