/*
 * @author: Razvan Rauta
 * Date: 03/12/2019
 * Time: 13:13
 */

import React from 'react';
import styles from './ScrollToTop.module.scss';
import $ from 'jquery';

class ScrollToTopLandscape extends React.Component {
	constructor() {
		super();

		this.state = {
			intervalId: 0
		};
	}

	scrollStep() {
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
	}

	scrollToTop() {
		const orientation = window.screen.orientation;

		if (typeof orientation !== 'undefined') {
			if (orientation.angle === 90 || orientation.angle === -90) {
				$('body').animate({ scrollTop: 0 }, 500, 'swing', 0);
			}
		}

		let intervalId = setInterval(
			this.scrollStep.bind(this),
			this.props.delayInMs
		);
		this.setState({ intervalId: intervalId });
	}

	render() {
		return (
			<span
				title="Back to top"
				className={styles.backToTop}
				onClick={() => {
					this.scrollToTop();
				}}
			/>
		);
	}
}

export default ScrollToTopLandscape;
