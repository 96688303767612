/*
 * @author: Razvan Rauta
 * Date: 15/11/2019
 * Time: 16:37
 */

import React from 'react';
import './error.sass';
import { Link } from 'react-router-dom';
import imageMobile from '../../layout/images/404/404_pic_mobile.png';
import imageMobile2x from '../../layout/images/404/404_pic_mobile@2x.png';
import image from '../../layout/images/404/404_pic_desktop.png';
import image2x from '../../layout/images/404/404_pic_desktop@2x.png';

const Error = () => (
	<div className="errorPage">
		<div className="background" />
		<div className="errorContainer">
			<div className="inner-content">
				<picture className="picture">
					<source
						media="(min-width: 900px)"
						srcSet={`${image} 1x, ${image2x} 2x`}
					/>
					<img
						className="advantage__img"
						src={imageMobile}
						srcSet={`${imageMobile2x} 2x`}
						alt="not found"
					/>
				</picture>
				<p className="subheading">PAGE NOT FOUND</p>
				<Link className="home" to="/">
					VISIT HOMEPAGE
				</Link>
			</div>
		</div>
	</div>
);

export default Error;
