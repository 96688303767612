/**
 * @author Razvan Rauta
 * 19/02/2020
 * 15:58
 */

const NewsPostsActionType = {
	FETCH_JWT_TOKEN_START: 'FETCH_JWT_TOKEN_START',
	FETCH_JWT_TOKEN_SUCCESS: 'FETCH_JWT_TOKEN_SUCCESS',
	FETCH_JWT_TOKEN_FAILURE: 'FETCH_JWT_TOKEN_FAILURE',
	FETCH_POSTS_START: 'FETCH_POSTS_START',
	FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
	FETCH_POSTS_FAILURE: 'FETCH_POSTS_FAILURE'
};

export default NewsPostsActionType;
