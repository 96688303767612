import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './Modal.module.scss';

export const Modal = ({ handleClose, children }) => (
	<div className={styles.ModalBox}>
		<div className={styles.Modal}>
			<div className={styles.ModalCloseWrapper} onClick={handleClose}>
				<FontAwesomeIcon icon={faTimes} className={styles.ModalClose} />
			</div>
			{children}
		</div>
	</div>
);
