/*
 * @author: Razvan Rauta
 * Date: 02/10/2019
 * Time: 15:44
 */
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import './Employee.scss';
import mail from '../../layout/images/Mail.svg';
import doNotDisturb from '../../layout/images/do_not_disturb.svg';
import phoneIcon from '../../layout/images/Phone.svg';
import officeIcon from '../../layout/images/office-icon.svg';
import homeIcon from '../../layout/images/home-icon.svg';
import clipBoard from '../../layout/images/clipboard.svg';
import profilePictureAwardBanner from '../../layout/images/profile_picture_award_banner.svg';
import config from '../../config';
import { GoogleAEvent } from '../../services/GoogleAEvent';
import ProjectsAndDepartments from '../ProjectsAndDepartments/ProjectsAndDepartments';

const filterKlikaBadges = (badges = []) =>
	badges.filter(badge => RegExp(/^kli?ka_.*/).test(badge.slug));
const filterNotKlikaBadges = (badges = []) =>
	badges.filter(badge => !RegExp(/^kli?ka_.*/).test(badge.slug));

class Employee extends Component {
	static propTypes = {
		isOpened: PropTypes.bool
	};

	static defaultProps = {
		isOpened: false
	};

	constructor(props) {
		super(props);
		const { isOpened } = this.props;
		this.state = {
			iconClass1: 'offButton',
			iconClass2: 'offButton',
			copyText: 'Copy to clipboard',
			phoneClass: ['offButton', 'offButton', 'offButton', 'offButton'], // copy icon styles for max 4 phone numbers
			isOpened
		};
		this.handleMouseLeave.bind(this);
		this.handleOnClick.bind(this);
		this.employeeRef = React.createRef();
	}

	handleOnClick = event => {
		event.stopPropagation();
		window.history.replaceState(null, null, `/?employeeId=${this.props.email}`);
		this.employeeRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	sendEventToGoogle = (event, name, location) => {
		GoogleAEvent(event, name, location);
	};

	handleMouseLeave = () => {
		setTimeout(() => this.setState({ copyText: 'Copy to clipboard' }), 500);
	};

	renderLocation = (
		officeLocation,
		firstName,
		lastName,
		floorSection = null,
		city = null
	) => {
		const { officeLink } = config;

		const { buildingId, description } = officeLocation;

		if (
			buildingId === 'Minsk-Office-1' ||
			buildingId === 'Almaty-Office-1' ||
			buildingId === 'Wroclaw-Office-1'
		) {
			return (
				<div className="locationContainer">
					<a
						className="locationLink"
						href={`${officeLink}/${buildingId.toLowerCase()}/?search=${encodeURIComponent(
							firstName + ' ' + lastName
						)}`}
						target="_blank"
						rel="noopener noreferrer"
						onClick={event => {
							event.stopPropagation();
							this.sendEventToGoogle(
								'CLICK',
								'Employee desk location',
								'EMPLOYEE_CARD'
							);
						}}
					>
						<img alt="Location Tag" className="locationIcon" src={officeIcon} />
						<p className="locationText">{`${description}, ${
							floorSection ? 'Room ' + floorSection.split('.')[0] : ''
						}`}</p>
					</a>
				</div>
			);
		} else {
			return (
				<div className="locationContainer">
					<img alt="Home Icon" className="homeIcon" src={homeIcon} />
					<p className="homeText">{`${description}${
						city ? `, ${city}` : ''
					}`}</p>
				</div>
			);
		}
	};

	renderProjectsAndDepartments = (
		department,
		handleEmployeeId,
		projects,
		supervisor,
		email,
		isPortfolioManager,
		portfolio,
		portfolioManagers,
		deputy
	) => (
		<ProjectsAndDepartments
			department={department}
			handleEmployeeId={handleEmployeeId}
			projects={projects}
			supervisor={supervisor}
			currentEmployee={email}
			badges={filterNotKlikaBadges(this.props.badges)}
			isPortfolioManager={isPortfolioManager}
			portfolio={portfolio}
			portfolioManagers={portfolioManagers}
			deputy={deputy}
		/>
	);

	render() {
		const Highlight = require('react-highlighter');
		const {
			isOpened,
			iconClass1,
			iconClass2,
			phoneClass,
			copyText
		} = this.state;
		const clipBoardIcon1 = ['clipboardIcon', iconClass1];
		const clipBoardIcon2 = ['clipboardIcon', iconClass2];

		// copy icon styles for max 4 phone numbers
		const classArray = [
			['clipboardIcon', phoneClass[0]],
			['clipboardIcon', phoneClass[1]],
			['clipboardIcon', phoneClass[2]],
			['clipboardIcon', phoneClass[3]]
		];
		let {
			profilePicture,
			id,
			query,
			firstName,
			lastName,
			title,
			email,
			phoneNumbers,
			floorSection,
			officeLocation,
			projects,
			handleEmployeeId,
			supervisor,
			department,
			city,
			isPortfolioManager,
			portfolio,
			portfolioManagers,
			deputy,
			leave,
			badges
		} = this.props;
		let displayButton;
		let cursor;
		const collapseDesktop = {
			collapse: 'ReactCollapse--collapse collapseDesktop',
			content: 'ReactCollapse--content collapseDesktop'
		};

		const collapseMobile = {
			collapse: 'ReactCollapse--collapse collapseMobile',
			content: 'ReactCollapse--content collapseMobile'
		};

		if (process.env.NODE_ENV === 'development') {
			profilePicture = profilePicture.replace('api', 'web');
		}

		if (
			(projects.length === 0 &&
				typeof supervisor === 'undefined' &&
				typeof department === 'undefined') ||
			(projects.length === 1 &&
				projects[0].is_hidden &&
				projects[0].project_managers.length === 0)
		) {
			displayButton = 'none';
			cursor = 'default';
			collapseDesktop.collapse += ' none';
			collapseMobile.collapse += ' none';
		} else {
			displayButton = 'flex';
			cursor = 'pointer';
		}

		const leaveEndDate = new Date(leave?.endDate).setDate(
			new Date(leave?.endDate).getDate() + 1
		);

		const klikaBadges = filterKlikaBadges(badges);
		const latestBadge = klikaBadges.pop();

		return (
			<div
				className="employeeCard"
				style={{ cursor: cursor }}
				ref={this.employeeRef}
			>
				<div
					className="row"
					id={id}
					onClick={() =>
						projects.length > 1 ||
						typeof supervisor !== 'undefined' ||
						typeof department !== 'undefined' ||
						(projects.length === 1 &&
							projects[0].is_hidden &&
							projects[0].project_managers.length !== 0)
							? this.setState({ isOpened: !isOpened })
							: {}
					}
				>
					<div className="profile-image">
						<button className="avatarButton" onClick={this.handleOnClick}>
							<div
								className="image-wrapper"
								style={{
									backgroundImage: `url(${profilePicture})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									position: 'relative'
								}}
							>
								{!!latestBadge && (
									<div
										className="award-banner"
										style={{
											backgroundImage: `url(${profilePictureAwardBanner})`
										}}
									>
										<p className="award-banner-text">
											{latestBadge.description}
										</p>
									</div>
								)}
							</div>
						</button>
					</div>
					<div className={`profile-info ${isOpened ? 'opened' : ''}`}>
						<div
							className="name-and-title-container"
							onMouseEnter={() => this.setState({ iconClass1: 'onButton' })}
							onMouseLeave={() => this.setState({ iconClass1: 'offButton' })}
						>
							<div className="name-container">
								<button className="fullNameButton" onClick={this.handleOnClick}>
									<Highlight
										search={query}
									>{`${firstName} ${lastName}`}</Highlight>
									{!!latestBadge && (
										<div className="award-container">
											<img
												className="award-picture"
												src={latestBadge.path}
												alt={latestBadge.description}
											/>
										</div>
									)}
								</button>
								<button
									className="copyToClipboardBtn"
									onMouseLeave={this.handleMouseLeave}
									onClick={event => {
										event.stopPropagation();
										this.sendEventToGoogle(
											'USER',
											'Employee name was copied',
											'EMPLOYEE_CARD'
										);
									}}
								>
									<OverlayTrigger
										key={'overlayName'}
										delay={200}
										placement={'right'}
										overlay={<Tooltip id={`tooltip-name`}>{copyText}</Tooltip>}
									>
										<CopyToClipboard
											text={`${firstName} ${lastName}`}
											onCopy={() => this.setState({ copyText: 'Copied' })}
										>
											<img
												alt="Copy to clipboard"
												className={`${clipBoardIcon1.join(' ')} copy-name`}
												src={clipBoard}
											/>
										</CopyToClipboard>
									</OverlayTrigger>
								</button>
							</div>
							<div className="title">
								<Highlight search={query}>{title}</Highlight>
							</div>
							{department ? (
								<div className="department-name">
									<span>{`${department.department_name} Department`}</span>
								</div>
							) : (
								''
							)}
							{leave ? (
								<div className="leave-event">
									<div className="leave-unavailable">
										<img
											className="do-not-disturb-icon"
											alt="do-not-disturb-icon"
											src={doNotDisturb}
										/>
										<p>Currently unavailable</p>
									</div>
									<div className="leave-date">
										<img
											className="do-not-disturb-icon"
											alt="do-not-disturb-icon"
											src={doNotDisturb}
										/>
										<p className="leave-date-text">
											Until{' '}
											{new Intl.DateTimeFormat('en-GB', {
												month: 'short',
												day: 'numeric',
												year: 'numeric'
											}).format(leaveEndDate)}
											{deputy ? ', ' : ''}
										</p>
										{deputy ? (
											<p className="contact-the-deputy">Contact the deputy</p>
										) : (
											<></>
										)}
									</div>
								</div>
							) : null}
						</div>
						<div className="contacts">
							<div
								className="triggerContainer triggerDesktop"
								style={{ display: displayButton }}
							>
								<span
									className={`openButton ${
										isOpened ? 'openButtonOpen' : 'openButtonClose'
									}`}
									onClick={() =>
										this.setState({ isOpened: !isOpened }, () => {
											this.sendEventToGoogle(
												'CLICK',
												'Employee projects and managers trigger',
												'EMPLOYEE_CARD'
											);
										})
									}
								/>
							</div>
							<div
								className="eMail"
								onMouseEnter={() => this.setState({ iconClass2: 'onButton' })}
								onMouseLeave={() => this.setState({ iconClass2: 'offButton' })}
								onClick={event => {
									event.stopPropagation();
									this.sendEventToGoogle(
										'CLICK',
										'Employee e-mail',
										'EMPLOYEE_CARD'
									);
								}}
							>
								<a href={`mailto:${email}`}>
									<img className="email-icon" src={mail} alt="email" />
									<Highlight search={query}>{email}</Highlight>
								</a>
								<button
									className="copyToClipboardBtn"
									onMouseLeave={this.handleMouseLeave}
									onClick={event => {
										event.stopPropagation();
										this.sendEventToGoogle(
											'USER',
											'Employee e-mail was copied',
											'EMPLOYEE_CARD'
										);
									}}
								>
									<OverlayTrigger
										key={'overlayEmail'}
										placement={'right'}
										delay={200}
										overlay={<Tooltip id={`tooltip-email`}>{copyText}</Tooltip>}
									>
										<CopyToClipboard
											text={email}
											onCopy={() => this.setState({ copyText: 'Copied' })}
										>
											<img
												alt="Copy to clipboard"
												className={clipBoardIcon2.join(' ')}
												src={clipBoard}
											/>
										</CopyToClipboard>
									</OverlayTrigger>
								</button>
							</div>

							{phoneNumbers.map((phone, index) => {
								if (index < 4) {
									return (
										<div
											key={uuid.v1()}
											className="phoneNumber"
											onMouseEnter={() => {
												const { phoneClass } = this.state;
												phoneClass[index] = 'onButton';
												this.setState({ phoneClass });
											}}
											onMouseLeave={() => {
												const { phoneClass } = this.state;
												phoneClass[index] = 'offButton';
												this.setState({ phoneClass });
											}}
											onClick={event => {
												event.stopPropagation();
												this.sendEventToGoogle(
													'CLICK',
													'Employee phone number',
													'EMPLOYEE_CARD'
												);
											}}
										>
											<a href={`tel:${phone.split('ext.')[0]}`}>
												<img
													className="phone-icon"
													src={phoneIcon}
													alt="phone"
												/>
												<Highlight search={query}>
													{phone.split('ext.')[0] + ' '}
												</Highlight>
											</a>
											{phone.split('ext.')[1] && (
												<span className="extension">
													&nbsp;{'ext. ' + phone.split('ext.')[1]}
												</span>
											)}
											<button
												className="copyToClipboardBtn"
												onMouseLeave={this.handleMouseLeave}
												onClick={event => {
													event.stopPropagation();
													this.sendEventToGoogle(
														'USER',
														'Employee phone number was copied',
														'EMPLOYEE_CARD'
													);
												}}
											>
												<OverlayTrigger
													key={'overlayPhone'}
													placement={'right'}
													delay={200}
													overlay={
														<Tooltip id={`tooltip-phone`}>{copyText}</Tooltip>
													}
												>
													<CopyToClipboard
														text={phone}
														onCopy={() => this.setState({ copyText: 'Copied' })}
													>
														<img
															alt="Copy to clipboard"
															className={classArray[index].join(' ')}
															src={clipBoard}
														/>
													</CopyToClipboard>
												</OverlayTrigger>
											</button>
										</div>
									);
								}
							})}
							{officeLocation &&
								this.renderLocation(
									officeLocation,
									firstName,
									lastName,
									floorSection,
									city
								)}
						</div>

						<Collapse theme={collapseDesktop} isOpened={isOpened}>
							{this.renderProjectsAndDepartments(
								department,
								handleEmployeeId,
								projects,
								supervisor,
								email,
								isPortfolioManager,
								portfolio,
								portfolioManagers,
								deputy
							)}
						</Collapse>
					</div>
					<div
						className="triggerContainer triggerMobile"
						style={{ display: displayButton }}
					>
						<span
							className={`openButton ${
								isOpened ? 'openButtonOpen' : 'openButtonClose'
							}`}
							onClick={() => this.setState({ isOpened: !isOpened })}
						/>
					</div>
					<Collapse isOpened={isOpened} theme={collapseMobile}>
						{this.renderProjectsAndDepartments(
							department,
							handleEmployeeId,
							projects,
							supervisor,
							email,
							isPortfolioManager,
							portfolio,
							portfolioManagers
						)}
					</Collapse>
				</div>
			</div>
		);
	}
}

export default Employee;
