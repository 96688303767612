/**
 * @author Razvan Rauta
 * 18/12/2019
 * 13:19
 */

import React from 'react';

const EmployeesContext = React.createContext();

export const EmployeesProvider = EmployeesContext.Provider;
export const EmployeesConsumer = EmployeesContext.Consumer;

export default EmployeesContext;
